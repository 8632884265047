import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './article.css';
import axios from 'axios';
import UsePageSEO from '../../hooks/UsePageSEO/index'
import { useTranslation } from 'react-i18next';

const Article = () => {
  UsePageSEO({
    title :" Blogs ",
    description:"Explore our latest blogs covering a variety of topics, from lifestyle and technology to health and travel. Stay updated with insightful articles, tips, and trends that inspire and inform.",
  });
  const { t } = useTranslation();
  const [articles, setArticles] = useState({
    loading: true,
    results: [],
    err: null,
    reload: "0"
  });

  useEffect(() => {
    setArticles(prevState => ({ ...prevState, loading: true }));
    axios.get("https://api.goldenpharaohtours.com/getallarticals")
      .then(resp => {
        setArticles(prevState => ({
          ...prevState,
          results: resp.data,
          loading: false,
          err: null
        }));
      })
      .catch(() => {
        setArticles(prevState => ({
          ...prevState,
          loading: false,
          results: [],
          err: "Failed to fetch articles"
        }));
      });
  }, [articles.reload]);

  const toggleReadMore = (index) => {
    setArticles(prevState => ({
      ...prevState,
      results: prevState.results.map((article, i) => {
        if (i === index) {
          return { ...article, showMore: !article.showMore };
        }
        return article;
      })
    }));
  };

  return (
    
    <div className="site-section" data-aos="fade-up">
      {articles.loading ? (
        <div className="loading-spinner-overlay">
          <div className="loading-spinner-container">
            <div className="loading-spinner">&#9765;</div>
            <span>Loading...</span>
          </div>
        </div>
      ) : (
<Container className="article-container">
  <Row className="justify-content-center">
    {articles.results.map((article, index) => (
      <Col 
        key={index} 
        xs={12}  // Full width on extra small screens
        sm={6}   // Half width on small screens (mobile)
        md={6}   // Half width on medium screens (tablet)
        lg={6}   // Half width on large screens (desktop)
        className="d-flex justify-content-center mb-4" // Add margin bottom for spacing
      >
        <Card className="article-card">
          <div className="meta">
            <Carousel className="card-image">
              {(article.imgs || []).map((imgs, imgIndex) => (
                <Carousel.Item 
                  key={imgIndex} 
                  className="img-fluid rounded animate-img-swipe"
                >
                  <Card.Img
                    variant="top"
                    src={`https://api.goldenpharaohtours.com/uploads/${imgs}`}
                    alt={`Image ${imgIndex + 1}`}
                  />
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
          <div className="content">
            <Card.Body>
              <Card.Title style={{ color: 'orange', fontWeight: 'bolder' }}>
                {article.title}
              </Card.Title>
              <Card.Text style={{ textAlign: 'center', margin: '25px' }}>
                {article.showMore ? article.descreption : `${article.descreption.substring(0, 100)}...`}
                <span className="read-more" onClick={() => toggleReadMore(index)}>
                  {article.showMore ? ' Show Less' : ' Read More'}
                </span>
              </Card.Text>
            </Card.Body>
          </div>
        </Card>
      </Col>
    ))}
  </Row>
</Container>

      )}
    </div>
  );
};

export default Article;
