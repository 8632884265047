import React, { useState, useEffect } from "react";
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import "../../Assets/css/spinner.css";
import { useNavigate } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card'

const CreateArticle = () => {
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [preview, setPreview] = useState([]);

    const navigate = useNavigate();

    const [article, setArticle] = useState({
        title: "",
        descreption: "",
        err: []
    });
    const Do_Post = async (e) =>{
        e.preventDefault();

        const formData = new FormData();
        formData.append("title",article.title);
        formData.append("descreption",article.descreption);
        for (let i = 0; i < images.length; i++) {
            formData.append("images", images[i]);
        }
        setLoading(true);
        {/*for (let pair of formData.entries()) {
            console.log(pair[0], pair[1]);
        }
            */}
        try{
            const response = await axios.post("https://api.goldenpharaohtours.com/createarticals",formData,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            navigate("/");
            setArticle({ ...article, loading: false, err: [] });
        } catch (error) {
            console.log(error);
            setArticle({ ...article, loading: false, err: [error.response.data.msg] });
        } finally {
            setLoading(false);
        }
    };
    const handleImageChange = (event) => {
        const files = event.target.files;
        setImages(files);

        const imagesPreview = [];
        for (let i = 0; i < files.length; i++) {
            const reader = new FileReader();
            reader.readAsDataURL(files[i]);
            reader.onload = () => {
                imagesPreview.push(reader.result);
                if (imagesPreview.length === files.length) {
                    setPreview(imagesPreview);
                }
            };
        }
    };
    const handleResetClick = () => {
        setPreview(null);
        setImages([]);
    };


   


    return (
        <div className="create-post-container">
            <br></br><br></br><br></br>
            <Card className="create-post-card">
                <Card.Body>
                    <h1>Create Blog</h1>
                    {article.err.map((error, index) => (
                        <Alert key={index} variant='danger'>
                            {error}
                        </Alert>
                    ))}
                    <Form onSubmit={Do_Post} className="post-form">
                        <Form.Group className="article-title-group">
                            <Form.Label>Blog Title</Form.Label>
                            <Form.Control
                                type="text"
                                value={article.title}
                                onChange={(e) => setArticle({ ...article, title: e.target.value })}
                                placeholder="Enter Blog title"
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Blog descreption</Form.Label>
                            <Form.Control
                                as="textarea"
                                value={article.descreption}
                                onChange={(e) => setArticle({ ...article, descreption: e.target.value })}
                                rows={5}
                                placeholder="Write Blog description here..."
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Upload Blog Image</Form.Label>
                            <Form.Control
                                type="file"
                                onChange={handleImageChange}
                                accept="image/*"
                                multiple
                            />
                            {preview.length > 0 && preview.map((image, index) => (
                                <Image key={index} src={image} alt={`Preview ${index}`} thumbnail />
                            ))}
                        </Form.Group>

                        <div className="d-flex justify-content-between">
                            <Button variant="secondary" onClick={handleResetClick}>Reset Images</Button>
                            <Button variant="primary" type="submit" disabled={loading}>
                                {loading ? "Creating..." : "Create Article"}
                            </Button>
                        </div>
                    </Form>
                </Card.Body>
            </Card>
        </div>
    );
};

export default CreateArticle;
